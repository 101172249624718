import { VALIDATE_TOKEN, INIT_LOADING, FINISH_LOADING } from '../action-types.js';
import api from 'Common/api';
import axiosConfig from 'Common/axiosConfig'

export const validateToken = token => async dispatch => {
  dispatch({type: INIT_LOADING})
  try {
    const res = await api.user.getUser(token);
    axiosConfig.defaults.headers.Authorization = `Bearer ${token}`;

    return dispatch({ type: VALIDATE_TOKEN, payload: res.data })
  } catch (error) {
    console.log(error.response)
  } finally {
    dispatch({type: FINISH_LOADING})
  }
}
