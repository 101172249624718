import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { validateToken } from 'Redux/actions/user-actions'

import { Result, Spin } from 'antd';

import config from 'Common/config'

const { LOGIN_URL } = config

class Auth extends Component {
  static propTypes = {
    user: PropTypes.object,
    app: PropTypes.object,
    validateToken: PropTypes.func,
  }
  componentDidMount() {
    const { user, location, history, validateToken } = this.props
    const queryParams = new URLSearchParams(location.search)
    const tokenParam = queryParams.get('token');

    if (!tokenParam) {
      window.location.href = LOGIN_URL
    } else {
      const token = tokenParam || user.token
      validateToken(token).then(() => {
        queryParams.delete('token')
        history.replace(`${location.pathname}?${queryParams.toString()}`)
      });
    }
  }

  render() {
    const { user, children, app } = this.props;

    if (app.isLoading) {
      return  <Spin />;
    }

    if (isEmpty(user)) {
      return <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />;
    }

    return  children;
  }
}
const mapStateToProps = state => ({
  user: state.user,
  app: state.app,
})
const mapDispatchToProps = {
  validateToken,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth))
