import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import Auth from 'Containers/Auth'
import Home from 'Containers/Home'
import Files from 'Containers/Files'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import { WhiteLayout, PaddedContent } from 'Common/styled'

class App extends Component {
  static propTypes = {
    allTours: PropTypes.object,
    currentTour: PropTypes.string,
    initTours: PropTypes.func,
    resetTour: PropTypes.func,
    setTour: PropTypes.func,
    tourSteps: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
  }
  componentDidMount() {

  }

  render() {
    const {
      user,
    } = this.props

    return (
      <WhiteLayout>
        <Header
          user={user}
        />
        <Auth>
          <PaddedContent>
            <Switch>
              <Route component={Home} exact path={'/'} />
              <Route component={Files} path={'/files'} />
              <Route component={Page404} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(App)
