import API from './axiosConfig.js'

// EXAMPLE APIS
// Reference: https://codeburst.io/how-to-call-api-in-a-smart-way-2ca572c6fe86 (Solution #2)
export default {
  user: {
    getUser: (token) => API.get('/user', { headers: { Authorization: `Bearer ${token}` } }),
  },
  files: {
    uploadFile: (form) => {
      const formData = new FormData();

      formData.append('year', form.year);
      formData.append('month', form.month);
      formData.append('file', form.file.file);

      return API.post('/upload', formData, {headers: {
        'Content-Type': 'multipart/form-data',
      }});
    },
    getFiles: () => API.get('/list'),
    getSignedUrl: (fileName) => API.get('/fileUrl', { params: { fileName } })
  }
};
