import { omit } from 'ramda'
import {
  INIT_TOURS,
  SET_TOUR,
  RESET_TOUR,
  RESET_ALL_TOURS,
  SET_TOUR_DATA,
  RESET_TOUR_DATA,
} from '../action-types'
import { ALL_TOURS } from 'Common/constants'

const initialState = {
  allTours: {},
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_TOURS:
      const initState = {}
      ALL_TOURS.forEach(e => {
        initState[e] = !!localStorage.getItem(e)
      })
      return { ...state, allTours: initState }
    
      case SET_TOUR:
      localStorage.setItem(payload, 'true')
      return { ...state, allTours: { ...state.allTours, [payload]: true } }
    
      case RESET_TOUR:
      localStorage.setItem(payload, 'false')
      return { ...state, allTours: { ...state.allTours, [payload]: false } }
    
      case RESET_ALL_TOURS:
      const removeState = {}
      ALL_TOURS.forEach(e => {
        localStorage.removeItem(e)
        removeState[e] = false
      })
      return { ...state, allTours: removeState }

    case SET_TOUR_DATA:
      return { ...state, ...payload }
    case RESET_TOUR_DATA:
      return omit(['current', 'steps'], state)
    default:
      return state
  }
}
