import React, { Component } from 'react';

import { PageTitle } from 'Common/styled';

import Form from 'Containers/Form';

class Home extends Component {
  render() {
    return (
      <>
        <PageTitle>
          <div className="site-layout-content">
            <Form />
          </div>
        </PageTitle>
      </>
    );
  }
}

export default Home;
