import React, { useEffect, useState } from 'react';
import api from 'Common/api';
import { List, Button, Spin} from 'antd';

export default () => {
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getUrl = async (name) => {
        const url = await api.files.getSignedUrl(name);

        if (typeof window !== 'undefined') {
        window.location.href = url.data;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        const getFilesAsync = async () => {
        const fileNames = await api.files.getFiles();
            setFiles(fileNames.data);
            setIsLoading(false);
        };

        getFilesAsync();
    }, []);

    

    const list = (
        files
            && (
            <Spin spinning={isLoading}>
                <List
                    size="small"
                    header={<div>List of generated files</div>}
                    bordered
                    dataSource={files}
                    renderItem={file => <List.Item><Button type='link' onClick={() => getUrl(file)}>{file}</Button></List.Item>}
                />
            </Spin>
        )
    );

    return list;
}
