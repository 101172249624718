// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

// APP
export const INIT_LOADING = "INIT_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

// TOUR
export const INIT_TOURS = 'INIT_TOURS'
export const SET_TOUR = 'SET_TOUR'
export const RESET_TOUR = 'RESET_TOUR'
export const RESET_ALL_TOURS = 'RESET_ALL_TOURS'
export const SET_TOUR_DATA = 'SET_TOUR_DATA'
export const RESET_TOUR_DATA = 'RESET_TOUR_DATA'
