import React, {useState} from 'react';
import {
  Form, Select, Button, Upload, Spin
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import api from 'Common/api';
import { handleServerError } from 'Common/utils';
import { notification } from 'antd'

export default () => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = values => {
    console.log('Success:', values);
    setIsLoading(true);

    api.files.uploadFile(values)
    .then(() => {
      console.log('file processed')
      notification.success({
        message: 'File uploaded',
        description: 'The file was generated and is listed to the Files menu',
      })
    })
    .catch((err) => {
      console.log('there was an error with the file')
      handleServerError(err)
    })
    .finally(() => {
      setIsLoading(false);
    })
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div
      style={{padding: '60px'}}
    >
      <h2>
        Generazione registri IVA
      </h2>
      <p>
        Carica un unico file ZIP contenente tutti i file csv relativi ad un solo mese.
        <br />
        Attenzione ... versione con IVA5% - non tratare registri precedenti a Luglio 2016.
      </p>

      <br />
      
      <Form
        onFinish={onFinish}
        layout='horizontal'
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Mese"
          name="month"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value="01">Gennaio</Select.Option>
            <Select.Option value="02">Febbraio</Select.Option>
            <Select.Option value="03">Marzo</Select.Option>
            <Select.Option value="04">Aprile</Select.Option>
            <Select.Option value="05">Maggio</Select.Option>
            <Select.Option value="06">Giugno</Select.Option>
            <Select.Option value="07">Luglio</Select.Option>
            <Select.Option value="08">Agosto</Select.Option>
            <Select.Option value="09">Settembre</Select.Option>
            <Select.Option value="10">Ottobre</Select.Option>
            <Select.Option value="11">Novembre</Select.Option>
            <Select.Option value="12">Dicembre</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Anno"
          name="year"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value="2024">2024</Select.Option>
            <Select.Option value="2023">2023</Select.Option>
            <Select.Option value="2022">2022</Select.Option>
            <Select.Option value="2021">2021</Select.Option>
            <Select.Option value="2020">2020</Select.Option>
            <Select.Option value="2019">2019</Select.Option>
            <Select.Option value="2018">2018</Select.Option>
            <Select.Option value="2017">2017</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="file"
          rules={[{ required: true }]}
        >
          <Upload 
            beforeUpload={() => {return false;}}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Spin spinning={isLoading}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Spin>
        </Form.Item>
      </Form>
    </div>
  );
};
