import { combineReducers } from 'redux'
import app from './app-reducer'
import user from './user-reducer'
import tour from './tour-reducer'

const combinedReducers = combineReducers({
  app,
  user,
  tour
})

export default combinedReducers
