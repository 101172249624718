import {INIT_LOADING, FINISH_LOADING} from '../action-types'

export const initialState = {
  isLoading: true,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_LOADING: 
      return { ...state, isLoading: true}
    case FINISH_LOADING:
      return { ...state, isLoading: false}
    default:
      return state
  }
}
