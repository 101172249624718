export const TOUR_HOME = 'tour-home'
export const TOUR_ABOUT = 'tour-about'
export const ALL_TOURS = [TOUR_HOME, TOUR_ABOUT]

export const tourHomeSteps = [
  {
    selector: 'h1', // HTML selector
    content: 'This is a title',
  },
]
export const tourAboutSteps = [
  {
    content: 'This is a secondary page',
  },
]
