import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'

const CustomMenu = props => {
  const location = useLocation();
  const { user } = props;

  return (
    !isEmpty(user) && <Menu
      mode={'horizontal'}
      selectedKeys={[location.pathname]}
      style={{ lineHeight: '57px' }}>
      <Menu.Item key={'/'}>
        <Link to={'/'}>{'Home'}</Link>
      </Menu.Item>
      <Menu.Item key={'/files'}>
        <Link to={'/files'}>{'Files'}</Link>
      </Menu.Item>
    </Menu>
  )
}

CustomMenu.propTypes = {
  user: PropTypes.object,
}
export default CustomMenu
