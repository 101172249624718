import styled from 'styled-components'
import { Layout, Avatar } from 'antd'
import { colors } from 'Common/constants'

export const HeaderBar = styled.div`
  height: 16px;
  background-color: ${colors.main};
`
export const StyledHeader = styled(Layout.Header)`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey2};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  display: grid;
  grid-gap: 16px;
  grid-template-columns: ${props =>
    props.hasTour ? 'auto 1fr repeat(2, auto)' : 'auto 1fr auto'};
  align-items: center;
  padding: 0 16px;
  z-index: 999;
`
export const AppLogo = styled.img`
  height: 32px;
`
export const TutorialIcon = styled(Avatar)`
  cursor: pointer;
`
